@import "loader";

@font-face {
  font-family: 'CentraNo2';
  font-style: normal;
  src: url("../fonts/App-Font-CentraNo2-Family-Desktop/CentraNo2-Book.otf");
}

@font-face {
  font-family: 'CentraNo2';
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/App-Font-CentraNo2-Family-Desktop/CentraNo2-Thin.otf");
}

@font-face {
  font-family: 'CentraNo2';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/App-Font-CentraNo2-Family-Desktop/CentraNo2-Light.otf");
}

@font-face {
  font-family: 'CentraNo2';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/App-Font-CentraNo2-Family-Desktop/CentraNo2-Book.otf");
}

@font-face {
  font-family: 'CentraNo2';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/App-Font-CentraNo2-Family-Desktop/CentraNo2-Medium.otf");
}

@font-face {
  font-family: Inter-Regular;
  src: url('../fonts/App-Font-CentraNo2-Family-Desktop/Inter-Regular.ttf') format('truetype')
}

* {
  font-family: "CentraNo2" !important;
}

#login-mobile * {
  font-family: sans-serif !important;
}

#login-desktop * {
  font-family: sans-serif !important;
}

#login-desktop {
  background: url("@res/images/desktop_login_background.svg") no-repeat top center fixed;
  background-size: cover;
}

#login-mobile {
  background: url("@res/images/mobile_login_background.svg") no-repeat top center fixed;
  background-size: cover;
}

body {
  margin: 0
}

div {
  //display: inline-block;
}

img {
  display: inline-block;
}

input {
  border: none;
  font-size: 16px;
}

/**
 * Flex shit
 */
.ll_v_l {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.ll_v_c {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ll_v_r {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.ll_h_t {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

.ll_h_c {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.ll_h_r {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}

/**
 * Dimension shit
 */
.match_height {
  height: 100%
}

.match_width {
  width: 100%
}

.center_h {
  margin-left: auto;
  margin-right: auto;
}

.center_v {
  margin-top: auto;
  margin-bottom: auto;
}

.center {
  margin: 0 auto;
}

.relative-center {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.clickable {
  cursor: pointer
}


.full_screen_overlay {
  position: absolute;
  z-index: 2;
}

.full_screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.right {
  right: 0;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.top {
  top: 0;
}
